<template>
  <v-container class="my-4">
    <AppBar ref="appBar" :items="itemsNavegacao" />

    <div v-if="carregandoContrato && !erroContrato" class="ml-carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-alert v-if="erroContrato" type="error" prominent text>
      <v-row align="center">
        <v-col class="grow">
          Não foi possível carregar as informações do contrato.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="getContrato($route.params.id)">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <div
      v-if="contrato"
      class="ma-auto elevation-6 rounded"
      style="width: 1000px; background-color: #ffffff !important"
    >
      <div>
        <div class="d-flex mx-4 pt-5">
          <div style="max-width: 500px !important">
            <p
              class="text-h5 titulo mb-0 font-weight-bold"
              @click="abrirDialogJson(contrato, 'Debug Contrato')"
            >
              {{ contrato.composicao || contrato.ref_externa }}
            </p>

            <p
              class="text-subtitle-2 mb-0 font-weight-regular"
              v-if="contrato.composicao"
            >
              {{ contrato.ref_externa }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex mt-2 align-start">
            <v-btn
              text
              color="primary"
              class="mr-1"
              small
              @click="abrirDialogJson(contrato.metadados, 'Metadados')"
            >
              metadados
            </v-btn>
            <v-chip
              small
              color="#448AFF"
              class="mr-1 mb-2 white--text font-weight-medium justify-center"
              v-if="contrato.perfil_contrato"
            >
              {{ contrato.perfil_contrato | perfilContrato }}
            </v-chip>
            <v-tooltip bottom v-if="contrato.status == 'CANCELADO'">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  style="min-width: 80px !important"
                  class="mr-1 mb-2 justify-center status-contrato font-weight-medium"
                  v-bind="attrs"
                  v-on="on"
                  :class="{
                    [contrato.status]: contrato.status,
                    'mr-6': !empresaSelecionada.acesso_autosservico,
                  }"
                  @click="abrirHistorico(contrato?.id)"
                  :disabled="carregandoHistorico"
                >
                  Cancelado {{ calculaDias(contrato.cancelado_em) }}
                </v-chip>
              </template>
              <span>
                Data de cancelamento:
                {{ contrato.cancelado_em | moment("L") }} <br />
                Motivo: {{ contrato.motivo_cancelado }}
              </span>
            </v-tooltip>
            <v-chip
              v-else
              small
              style="min-width: 80px !important"
              class="mr-1 mb-2 justify-center status-contrato font-weight-medium"
              :class="{
                [contrato.status]: contrato.status,
                'mr-6': !empresaSelecionada.acesso_autosservico,
              }"
              @click="abrirHistorico(contrato?.id)"
              :disabled="carregandoHistorico"
            >
              {{ contrato.status | statusContrato }}
            </v-chip>
            <v-menu
              v-if="empresaSelecionada.acesso_autosservico"
              max-width="250"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" style="margin-top: -5px !important">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :href="linkBoleto" target="_blank">
                  <v-list-item-content>
                    <v-list-item-title>
                      Visualizar Boleto Pendente
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="contrato.perfil_empresa || contrato.perfil"
                    >
                      Perfil da empresa:
                      <strong>
                        {{ contrato.perfil_empresa || contrato.perfil }}
                      </strong>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-list
          v-if="colunasOrdenadas"
          class="columns-list mx-4 pt-5 pb-5"
          light
        >
          <v-list-item
            style="border-bottom: 1px solid #ccc"
            two-line
            v-for="col in colunasOrdenadas"
            :key="col.chave"
          >
            <v-list-item-content v-if="col.chave.includes('metadados')">
              <v-list-item-title class="mb-2" v-if="col.chave.includes('_em')">
                {{
                  contrato[col.chave.split(".")[0]][col.chave.split(".")[1]]
                    ? formatarData(
                        contrato[col.chave.split(".")[0]][
                          col.chave.split(".")[1]
                        ]
                      )
                    : "-"
                }}
              </v-list-item-title>
              <v-list-item-title class="mb-2" v-else>
                <span :title="contrato[col.chave]">
                  {{
                    contrato[col.chave.split(".")[0]][
                      col.chave.split(".")[1]
                    ] || "-"
                  }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle v-if="col.nome.includes('dimensao')">{{
                nomeDimensao(col.chave)
              }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{
                col.nome || col.chave || "-"
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title class="mb-2" v-if="col.chave.includes('_em')">
                {{
                  contrato[col.chave] ? formatarData(contrato[col.chave]) : "-"
                }}
              </v-list-item-title>
              <v-list-item-title class="mb-2" v-else>
                <span :title="contrato[col.chave]">
                  {{ contrato[col.chave] || "-" }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle v-if="col.nome.includes('dimensao')">{{
                nomeDimensao(col.chave)
              }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{
                col.nome || col.chave || "-"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-row>
          <v-col></v-col>
        </v-row>

        <div v-if="linkBackofficeQuiteJa">
          <div class="d-flex align-center pa-4">
            <img
              src="https://backoffice.quiteja.com.br/assets/images/icons/favicon-32x32.png"
              class="mr-2"
              style="width: 32px"
            />

            <a
              :href="linkBackofficeQuiteJa"
              target="_blank"
              title="Negociação da QuiteJá"
              class="text-decoration-none"
            >
              {{ linkBackofficeQuiteJa }}
            </a>
          </div>

          <v-divider></v-divider>
        </div>

        <v-col id="parcelas" class="ma-auto parcelas pa-0">
          <div class="d-flex">
            <p class="text-h6 ml-4 mt-4">
              Parcelas ({{ parcelas.objects.length }})
            </p>
            <v-select
              v-model="filtroSelecionado"
              :items="filtros"
              attach
              text
              label="Filtros"
              multiple
              style="max-width: 490px !important"
              class="ml-5"
              no-data-text="Nenhum filtro cadastrado"
              @change="filtrarParcelas()"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index < 3">{{ `${item.text}` }} &nbsp; </span>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{ filtroSelecionado.length - 3 }}
                  {{
                    filtroSelecionado.length == 3
                      ? "selecionado"
                      : "selecionados"
                  }})
                </span>
              </template>
            </v-select>
          </div>
          <v-data-table
            :headers="parcelaDataHeader"
            :items="parcelasFiltradas"
            :items-per-page="100"
            hide-default-footer
            v-if="!erroParcelas"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @mouseover="selecionaItem(item)"
                  @mouseleave="desselecionarItem()"
                >
                  <td class="text-left id-tabela">#{{ item.id }}</td>
                  <td class="text-center">
                    {{ item.data_vencimento | moment("L") }}
                  </td>
                  <td class="text-center">
                    <span v-if="item.numero_parcela === 0">Entrada</span>
                    <span v-else-if="item.numero_parcela >= 1">
                      {{ item.numero_parcela }}ª
                    </span>
                  </td>
                  <td class="text-right">{{ item.valor | fomatadorReal }}</td>
                  <td class="text-right">
                    <div class="d-flex flex-column" v-if="item.valor_pago">
                      {{ item.valor_pago | fomatadorReal }}
                      <span
                        style="color: #8b8b8b; font-size: 12px"
                        class="text"
                      >
                        Pago em {{ item.pago_em | moment("ll") }}
                      </span>
                    </div>
                    <div
                      v-if="
                        item.status == 'PENDENTE' &&
                        verificaParcelaPausada(item)
                      "
                    >
                      Não notificável até
                      <b>{{ item.nao_notificar_ate | moment("L") }}</b>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <v-chip
                      v-if="item.status == 'PAGA'"
                      style="width: 100px"
                      class="justify-center status-parcela font-weight-medium"
                      :class="item.status"
                      small
                    >
                      {{ item.status | parcelaStatus }}
                    </v-chip>
                    <v-chip
                      v-if="
                        item.status == 'DESCONHECIDA' ||
                        item.status == 'PENDENTE'
                      "
                      style="width: 100px"
                      class="justify-center status-parcela font-weight-medium"
                      :class="item.status"
                      small
                    >
                      {{ item.status | parcelaStatus }}
                    </v-chip>
                    <v-tooltip bottom v-if="item.status == 'DESCARTADA'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          style="width: 100px"
                          class="justify-center status-parcela font-weight-medium"
                          :class="item.status"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.status | parcelaStatus }}
                        </v-chip>
                      </template>
                      <span>{{ item.motivo_descartado }}</span>
                    </v-tooltip>

                    <v-chip
                      style="width: 90px"
                      class="justify-center ml-1 chip-boleto font-weight-medium"
                      small
                    >
                      {{ item.metodo_pagamento | metodoPagamento }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="yellow"
                            class="ml-1"
                            v-bind="attrs"
                            v-on="on"
                            small
                            v-if="
                              !item.url_boleto &&
                              item.metodo_pagamento == 'BOLETO'
                            "
                          >
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>Boleto indisponível</span>
                      </v-tooltip>
                    </v-chip>
                  </td>
                  <td class="menu-tabela text-center">
                    <v-menu
                      v-if="item === itemSelecionado"
                      v-model="menuAberto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <input
                        type="hidden"
                        ref="linhaDigitavelCopia"
                        id="linhaDigitavel"
                        :value="item.linha_digitavel"
                        readonly
                      />
                      <v-list>
                        <v-list-item
                          v-if="item.url_boleto"
                          @click="downloadBoletoParcela(item)"
                        >
                          <v-list-item-title>Download boleto</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.linha_digitavel"
                          target="_blank"
                          @click="copiarLinhaDigitavel()"
                        >
                          <v-list-item-title>
                            Copiar código de barras
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="abrirDialogJson(item, 'Debug Parcela')"
                        >
                          <v-list-item-title>Detalhes</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="abrirDialogJson(item.metadados, 'Metadados')"
                        >
                          <v-list-item-title>Metadados</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="item.status == 'PENDENTE'"
                          target="_blank"
                          @click="abrirDialogPausarNotificacao(item)"
                        >
                          <v-list-item-title>
                            Pausar Notificações
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="carregandoParcelas">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
            <p class="text-center mt-2 pb-2 grey--text">
              Carregando parcelas...
            </p>
          </div>
          <div
            v-if="
              filtroSelecionado.length == 0 &&
              !carregandoParcelas &&
              !erroParcelas
            "
            class="pa-8 text-center text--secondary"
          >
            <p class="movimentacao-sem-dados mb-0">Nenhum filtro selecionado</p>
          </div>
          <div
            v-if="
              parcelasFiltradas.length == 0 &&
              filtroSelecionado.length > 0 &&
              !carregandoParcelas &&
              !erroParcelas
            "
            class="pa-8 text-center text--secondary"
          >
            <p class="movimentacao-sem-dados mb-0">
              Nenhuma parcela encontrada...
            </p>
          </div>
          <v-alert
            v-if="erroParcelas && !carregandoParcelas"
            type="error"
            prominent
            text
          >
            <v-row align="center">
              <v-col class="grow">
                Não foi possível carregar as parcelas.
              </v-col>
              <v-col class="shrink">
                <v-btn color="primary" @click="getTodasParcelas()">
                  Tentar novamente
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-divider></v-divider>
        </v-col>
        <v-col class="ma-auto pa-0">
          <p class="text-h6 ml-4 mt-6">
            Movimentações ({{ movimentacoes?.objects?.length || 0 }})
          </p>
          <v-data-table
            :headers="movimentacaoHeader"
            :items="movimentacoes?.objects"
            :items-per-page="100"
            hide-default-footer
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @mouseover="selecionaItem(item)"
                  @mouseleave="desselecionarItem()"
                >
                  <td class="id-tabela">#{{ item.id }}</td>
                  <td class="text-left" style="width: 160px">
                    {{ item.data_referencia | moment("L") }}
                  </td>
                  <td class="text-left">
                    <div class="d-flex">
                      <span>{{ nomeMovimentacao(item) }}</span>
                      <v-tooltip right v-if="item.descricao">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="ml-2" v-bind="attrs" v-on="on">
                            mdi-text-long
                          </v-icon>
                        </template>
                        <span>{{ item.descricao }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td class="text-right">
                    <span style="color: #8b8b8b; font-size: 12px" class="text">
                      Criado em {{ item.criado_em | moment("ll") }}
                    </span>
                  </td>
                  <td class="text-right pr-0">
                    <v-chip
                      small
                      style="width: 90px"
                      class="justify-center font-weight-medium"
                      :class="item.ativa ? 'ativa' : 'inativa'"
                    >
                      {{ item.ativa ? "Ativa" : "Inativa" }}
                    </v-chip>
                  </td>
                  <td class="menu-tabela">
                    <v-menu
                      v-if="item === itemSelecionado"
                      v-model="menuAberto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="abrirDialogJson(item, 'Debug Movimentação')"
                        >
                          <v-list-item-title> Detalhes </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="abrirDialogJson(item.metadados, 'Metadados')"
                        >
                          <v-list-item-title> Metadados </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="carregandoMovimentacoes">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
            <p class="text-center mt-2 pb-2 grey--text">
              Carregando movimentações...
            </p>
          </div>
          <div
            v-if="
              !movimentacoes?.num_results &&
              !erroCarregarMovimentacao &&
              !carregandoMovimentacoes
            "
            class="d-flex justify-center mt-3"
          >
            <p class="movimentacao-sem-dados">
              Nenhuma movimentação encontrada...
            </p>
          </div>
          <v-alert
            v-if="erroCarregarMovimentacao && !carregandoMovimentacoes"
            type="error"
            prominent
            text
          >
            <v-row align="center">
              <v-col class="grow">
                Não foi possível carregar as movimentações.
              </v-col>
              <v-col class="shrink">
                <v-btn color="primary" @click="carregaMovimentacoes()">
                  Tentar novamente
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </div>
    </div>
    <DialogJson ref="dialogJson" />
    <DialogPausarNotificacao
      ref="dialogPausarNotificacao"
      @parcelaPausada="ajustaParcelaPausada"
    />
    <DialogHistorico
      :dialog="modalHistorico"
      :historico="historicoContrato"
      title="do contrato"
      @fechar="modalHistorico = false"
    />
  </v-container>
</template>

<script>
import DialogJson from "../components/DialogJson.vue";
import { mapActions, mapGetters } from "vuex";
import environment from "@/assets/js/enviroment";
import AppBar from "../components/AppBar.vue";
import Vue from "vue";
import moment from "moment";
import DialogPausarNotificacao from "../components/DialogPausarNotificacao.vue";
import DialogHistorico from "../components/DialogHistorico";

export default {
  components: {
    AppBar,
    DialogJson,
    DialogPausarNotificacao,
    DialogHistorico,
  },
  async created() {
    await this.carregaContrato();
    await this.getTodasParcelas();
    await this.carregaMovimentacoes();
    this.filtrarParcelas();
  },

  watch: {
    empresaSelecionada: {
      handler() {
        this.$router.push({ name: "Contratos" });
      },
    },
  },

  data() {
    return {
      filtroSelecionado: ["PAGA", "PENDENTE", "DESCONHECIDA"],
      filtros: [],
      parcelasFiltradas: [],
      itemSelecionado: false,
      menuAberto: false,
      itemsNavegacao: [],
      parcelaDataHeader: [
        { text: "", value: "id", sortable: false, align: "start" },
        {
          text: "Vencimento",
          value: "data_vencimento",
          sortable: false,
          align: "center",
        },
        { text: "", value: "numero_parcela", sortable: false, align: "center" },
        {
          text: "Valor",
          value: "valor",
          sortable: false,
          align: "right",
        },
        {
          text: "Valor pago",
          value: "valor_pago",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "status",
          cellClass: "status",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "menu",
          align: "right",
          sortable: false,
        },
      ],

      movimentacaoHeader: [
        { text: "", value: "id", sortable: false, align: "start" },
        {
          text: "Data",
          value: "data_referencia",
          sortable: false,
          align: "left",
        },
        {
          text: "Nome",
          value: "chave",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          value: "criado_em",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "ativa",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "menu",
          align: "right",
          sortable: false,
        },
      ],
      carregandoMovimentacoes: false,
      erroCarregarMovimentacao: false,
      modalHistorico: false,
      carregandoHistorico: false,
    };
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("parcela", {
      parcelas: "parcelas",
      carregandoParcelas: "carregandoParcelas",
      erroParcelas: "erroParcelas",
    }),
    ...mapGetters("contrato", {
      contrato: "contrato",
      carregandoContrato: "carregandoContrato",
      erroContrato: "erroContrato",
      historicoContrato: "historico",
    }),
    ...mapGetters("contato", {
      telefones: "telefones",
      emails: "emails",
      carregandoTelefones: "carregandoTelefones",
      carregandoEmails: "carregandoEmails",
      erroCarregarTelefones: "erroCarregarTelefones",
      erroCarregarEmails: "erroCarregarEmails",
    }),
    ...mapGetters("movimentacao", {
      movimentacoes: "movimentacoes",
    }),

    /**
     * Link para o Backoffice da QuiteJá.
     * Só retorna para a empresa "QuiteJá".
     */
    linkBackofficeQuiteJa() {
      let empresa = this.$store.state.empresa || {};
      let empresaSelecionada = empresa.empresaSelecionada || {};
      let metadados = this.contrato.metadados || {};

      if (empresaSelecionada.id != environment.quitejaEmpresaId) {
        return null;
      }

      if (!metadados.deal_id) {
        return null;
      }

      return `${environment.quitejaBackofficeUrl}/deal/${metadados.deal_id}`;
    },

    /** Link para o download do boleto. */
    linkBoleto() {
      if (!this.contrato.identificador) {
        return null;
      }
      return `${environment.autosservicoUrl}/boleto/${this.contrato.identificador}`;
    },

    colunasOrdenadas() {
      const colunasEmpresa =
        this.$store.state.empresa.empresaSelecionada.colunas_contrato_mostrar;

      if (!colunasEmpresa) return null;

      let colunas = [];
      Object.entries(colunasEmpresa).forEach((col) => {
        colunas.push({ nome: col[1], chave: col[0] });
      });

      colunas = colunas.sort((a, b) => {
        if (a.chave.includes("metadados")) {
          if (
            this.contrato[a.chave.split(".")[0]]?.[a.chave.split(".")[1]] &&
            !this.contrato[b.chave.split(".")[0]]?.[b.chave.split(".")[1]]
          )
            return -1;
          if (
            !this.contrato[a.chave.split(".")[0]]?.[a.chave.split(".")[1]] &&
            this.contrato[b.chave.split(".")[0]]?.[b.chave.split(".")[1]]
          )
            return 1;

          return 0;
        }

        if (this.contrato[a.chave] && !this.contrato[b.chave]) return -1;
        if (!this.contrato[a.chave] && this.contrato[b.chave]) return 1;
        return 0;
      });

      return colunas;
    },
  },
  methods: {
    ...mapActions("parcela", {
      getParcelas: "getParcelas",
      limparParcelas: "limparParcelas",
      downloadBoleto: "downloadBoleto",
    }),
    ...mapActions("contrato", {
      getContrato: "getContrato",
    }),
    ...mapActions("contrato", {
      pegarHistoricoContrato: "pegarHistorico",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("movimentacao", {
      buscaMovimentacoes: "buscaMovimentacoes",
    }),

    async downloadBoletoParcela(parcela) {
      let payload = {
        url_boleto: parcela.url_boleto,
        numero_parcela: parcela.numero_parcela.toString(),
      };
      try {
        let retorno = await this.downloadBoleto(payload);
        window.open(retorno.url, "_self");
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Erro ao fazer o download do boleto.",
        });
      }
    },
    nomeMovimentacao(movimentacao) {
      if (movimentacao.nome) {
        return `${movimentacao.nome} (${movimentacao.chave})`;
      } else {
        return movimentacao.chave;
      }
    },
    quantidadeParcelasStatus() {
      let parcelasPendentes = 0;
      let parcelasPagas = 0;
      let parcelasExpiradas = 0;
      let parcelasDescartadas = 0;
      let parcelasDesconhecidas = 0;
      this.parcelas.objects.forEach((parcela) => {
        if (parcela.status == "PENDENTE") {
          parcelasPendentes += 1;
        } else if (parcela.status == "PAGA") {
          parcelasPagas += 1;
        } else if (parcela.status == "EXPIRADA") {
          parcelasExpiradas += 1;
        } else if (parcela.status == "DESCARTADA") {
          parcelasDescartadas += 1;
        } else if (parcela.status == "DESCONHECIDA") {
          parcelasDesconhecidas += 1;
        }
      });
      this.filtros.push(
        {
          header: "STATUS",
        },
        {
          text: `Pendente (${parcelasPendentes})`,
          value: "PENDENTE",
        },
        {
          text: `Paga (${parcelasPagas})`,
          value: "PAGA",
        },
        {
          text: `Expirada (${parcelasExpiradas})`,
          value: "EXPIRADA",
        },
        {
          text: `Descartada (${parcelasDescartadas})`,
          value: "DESCARTADA",
        },
        {
          text: `Desconhecida (${parcelasDesconhecidas})`,
          value: "DESCONHECIDA",
        }
      );
    },
    formatarData(data) {
      if (data.includes("T"))
        return `${moment(data).format("L")} ${moment(data).format("LT")}`;
      return moment(data).format("L");
    },
    filtrarParcelas() {
      if (!this.parcelas.objects) return;
      this.parcelasFiltradas = this.parcelas.objects.filter((canal) => {
        return this.filtroSelecionado.includes(canal.status);
      });
    },
    calculaDias(data) {
      if (!data) return;
      const canceladoEm = moment(data);
      let hoje = moment().startOf("day");
      const diasCancelamento = hoje.diff(canceladoEm, "days");
      if (diasCancelamento == 0) {
        return "hoje";
      } else {
        let dias = moment(data).from(hoje);
        return dias;
      }
    },
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    verificaParcelaPausada(parcela) {
      let hoje = moment().format("YYYY-MM-DD");
      if (parcela.nao_notificar_ate && parcela.nao_notificar_ate >= hoje)
        return true;
      return false;
    },
    ajustaParcelaPausada(parcelaPausada) {
      let index = this.parcelas.objects.findIndex((parcela) => {
        return parcela.id == parcelaPausada.id;
      });
      Vue.set(this.parcelas.objects, index, parcelaPausada);
    },
    carregaMovimentacoes() {
      this.carregandoMovimentacoes = true;
      let params = {
        contrato_id: this.$route.params.id,
        limite: 1000,
      };
      this.buscaMovimentacoes(params)
        .then((retorno) => {
          this.erroCarregarMovimentacao = false;
          this.carregandoMovimentacoes = false;
        })
        .catch((erro) => {
          this.carregandoMovimentacoes = false;
          this.erroCarregarMovimentacao = true;
          console.error(erro);
        });
    },
    abrirDialogPausarNotificacao(parcela) {
      this.$refs.dialogPausarNotificacao.abrirDialog(parcela);
    },
    copiarLinhaDigitavel() {
      if (!this.$refs.linhaDigitavelCopia[0]?.value) return;
      let input = this.$refs.linhaDigitavelCopia[0];
      input.setAttribute("type", "text");
      input.select();
      document.execCommand("copy");
      input.setAttribute("type", "hidden");

      this.showSnackBar({
        text: "Código de barras copiado com sucesso.",
      });
    },
    abrirDialogJson(dados, titulo) {
      this.$refs.dialogJson.abrirDialog(dados, titulo);
    },
    abrirDialog(dados, titulo) {
      this.$refs.dialogJson.abrirDialog(dados, titulo);
    },
    /** Retorna o nome amigável para uma dimensão. */
    nomeDimensao(dimensao) {
      if (!dimensao) {
        return;
      }

      let empresa = this.$store.state.empresa || {};
      let empresaSelecionada = empresa.empresaSelecionada || {};
      let dimensoes = empresaSelecionada.dimensoes_contrato || {};

      return dimensoes[dimensao] || dimensao.replace("dimensao_", "Dimensão ");
    },
    async abrirHistorico(id = null) {
      if (id) {
        this.carregandoHistorico = true;
        await this.pegarHistoricoContrato(id).then((retorno) => {
          if (retorno) {
            this.modalHistorico = true;
          } else {
            this.showSnackBar({
              text: `Erro ao buscar histórico`,
            });
          }
        });
        this.carregandoHistorico = false;
      }
    },
    async getTodasParcelas() {
      let payload = {
        limite: 1000,
        ordem: "numero_parcela",
        contrato_id: this.$route.params.id,
      };
      await this.getParcelas(payload);
      this.quantidadeParcelasStatus();
      this.filtrarParcelas();
    },
    async carregaContrato() {
      await this.getContrato(this.$route.params.id);
      if (!this.contrato) return;
      this.itemsNavegacao.push(
        {
          text: this.contrato?.cliente?.nome,
          subtext: Vue.filter("documentoCliente")(
            this.contrato.cliente.documento
          ),
          disabled: false,
          href: "/cliente/" + this.contrato.cliente.id,
        },
        {
          text: this.contrato.tipo,
          subtext: this.contrato.ref_externa,
          disabled: true,
        }
      );
    },
  },
  destroyed() {
    this.limparParcelas();
  },
};
</script>

<style lang="scss">
.columns-list {
  display: grid;
  grid-template-columns: 322.6px 322.6px 322.6px;
}
.chip-boleto {
  color: white !important;
  background-color: #100da5 !important;
}

.id-tabela {
  font-size: 12px !important;
}

.menu-tabela {
  width: 70px !important;
}

.movimentacao-sem-dados {
  font-weight: 700;
  font-size: 16px;
  color: #a6a6a6 !important;
}

.titulo {
  cursor: pointer;
}

.ativa {
  color: white !important;
  background-color: #4caf50 !important;
}

.inativa {
  color: #212121 !important;
  background-color: #bdbdbd !important;
}
</style>
