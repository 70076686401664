var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"elevation-7 my-6 pa-6 container rounded"},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-btn',{attrs:{"icon":"","exact":"","to":{
        name: 'VerCliente',
        params: { id: _vm.$route.query.cliente_id },
      }}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('div',{staticClass:"text-h6 font-weight-bold ml-3"},[_vm._v("Histórico de Notificações")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('p',{staticClass:"text-h5 mb-0"},[_vm._v(" Notificações ("+_vm._s(_vm.notificacoes.length || 0)+") ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.abrirDialogFiltros()}}},[_vm._v(" filtrar ")])],1)],1),(_vm.filtrosAplicados.length && !_vm.erroCarregarNotificacoes)?_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"py-0"},[_c('p',{staticClass:"text-subtitle-1 mb-1"},[_vm._v("Filtros Aplicados")])]),_c('v-col',{attrs:{"cols":"auto pt-0 pl-2"}},_vm._l((_vm.filtrosAplicados),function(filtro,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"black","outlined":"","close":""},on:{"click:close":function($event){return _vm.removeFiltros(filtro)}}},[_vm._v(" "+_vm._s(_vm.mostrarNome(filtro))+" ")])}),1)],1):_vm._e(),_c('v-row',[(_vm.carregandoNotificacoes && !_vm.erroCarregarNotificacoes)?_c('v-progress-circular',{staticClass:"d-flex mx-auto my-8 pb-8",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.erroCarregarNotificacoes && !_vm.carregandoNotificacoes)?_c('v-alert',{staticClass:"mx-auto mt-3",attrs:{"type":"error","prominent":"","text":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v("Erro ao carregar as notificações.")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.carregarNotificacoes()}}},[_vm._v(" Tentar novamente ")])],1)],1)],1):_vm._e(),(!_vm.erroCarregarNotificacoes && !_vm.carregandoNotificacoes)?_c('v-col',{attrs:{"cols":"12","id":"notifications"}},[(
          !_vm.erroCarregarNotificacoes &&
          !_vm.carregandoNotificacoes &&
          _vm.notificacoes?.length
        )?_c('v-divider'):_vm._e(),(
          !_vm.notificacoes?.length &&
          !_vm.erroCarregarNotificacoes &&
          !_vm.carregandoNotificacoes
        )?_c('p',{staticClass:"text-center mt-3 text-body-1 grey--text"},[_vm._v(" Nenhuma notificação encontrada! ")]):_vm._e(),_c('div',{attrs:{"id":"listaNotificacao"}},_vm._l((_vm.notificacoes),function(notificacao,index){return _c('v-list',{key:notificacao.id,staticClass:"py-0",attrs:{"color":"transparent"}},[_c('v-list-item',{staticClass:"pa-0",on:{"mouseover":function($event){return _vm.selecionaItem(notificacao)},"mouseleave":function($event){return _vm.desselecionarItem()}}},[_c('v-list-item-content',{staticClass:"d-flex"},[_c('v-list-item-title',{staticClass:"d-flex"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"flex-grow-0 pa-0 mr-2",attrs:{"cols":"auto"}},[_c('p',{staticClass:"mb-0 grey--text text-body-2 pt-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(notificacao.criado_em,"L"))+" às "+_vm._s(_vm.formatarHorario(notificacao.criado_em))+" ")])]),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(notificacao.tipo_notificacao === 'TESTE_EVENTO')?_c('v-chip',{staticClass:"text-center mr-1"},[_vm._v(" "+_vm._s(_vm._f("tipoNotificacao")(notificacao.tipo_notificacao))+" ")]):_vm._e()],1),(
                      notificacao.status == 'NAO_ENVIADO' ||
                      notificacao.status == 'ERRO'
                    )?_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"text-center mr-1",attrs:{"color":notificacao.status}},[_vm._v(" "+_vm._s(_vm._f("statusNotificacao")(notificacao.status))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"yellow","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(notificacao.motivo_erro || notificacao.motivo_nao_enviado || notificacao.motivo))])])],1)],1):_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"disabled":notificacao.status !== 'ENVIADO',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(notificacao.status)?_c('v-chip',_vm._g(_vm._b({staticClass:"text-center mr-1",attrs:{"color":notificacao.status}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("statusNotificacao")(notificacao.status))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(notificacao.enviado_em,"L"))+" às "+_vm._s(_vm.formatarHorario(notificacao.enviado_em)))])])],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"text-center mr-1",attrs:{"color":notificacao?.tipo}},[_vm._v(" "+_vm._s(_vm._f("canais")(notificacao?.tipo))+" ")])],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao == 'REGUA_COMUNICACAO'
                      )?_c('v-chip',{staticClass:"text-center white--text",attrs:{"color":"#9C27B0"}},[_vm._v(" "+_vm._s(_vm.retornarTextoNotificacao(notificacao))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao == 'REGUA_COMUNICACAO'
                      )?_c('v-chip',{staticClass:"text-center white--text ml-1",attrs:{"color":notificacao?.regua?.tipo}},[_vm._v(" "+_vm._s(_vm._f("tipoRegua")(notificacao?.regua?.tipo))+" ")]):_vm._e(),(
                        notificacao?.regua?.tipo == 'MOVIMENTACAO' &&
                        notificacao?.regua?.tipo_movimentacao
                      )?_c('v-chip',{staticClass:"text-center white--text ml-1",attrs:{"color":notificacao?.regua?.tipo}},[_vm._v(" "+_vm._s(notificacao.regua.tipo_movimentacao.nome)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                        _vm.carregandoFluxoAutomatico
                      )?_c('v-skeleton-loader',{attrs:{"type":"chip"}}):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                        !_vm.carregandoFluxoAutomatico
                      )?_c('v-chip',{staticClass:"text-center white--text mr-1",attrs:{"color":"#607D8B"}},[_vm._v(" Fluxo Automático ")]):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                        !_vm.carregandoFluxoAutomatico
                      )?_c('v-chip',{staticClass:"text-center white--text",attrs:{"color":"#9C27B0"}},[_vm._v(" "+_vm._s(_vm.retornarTextoNotificacao(notificacao))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"flex-grow-0 pa-0",attrs:{"cols":"auto"}},[(
                        notificacao.tipo_notificacao != 'FLUXO_AUTOMATICO' &&
                        notificacao.tipo_notificacao != 'REGUA_COMUNICACAO' &&
                        notificacao.tipo_notificacao != 'TESTE_EVENTO'
                      )?_c('v-chip',[_vm._v(" "+_vm._s(notificacao.tipo_notificacao)+" ")]):_vm._e()],1)],1)],1)],1),_c('v-list-item-action',{staticClass:"ma-0"},[(notificacao === _vm.itemSelecionado)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true),model:{value:(_vm.menuAberto),callback:function ($$v) {_vm.menuAberto=$$v},expression:"menuAberto"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.abrirDialogJson(notificacao, 'Debug Notificação')}}},[_c('v-list-item-title',[_vm._v(" Detalhes ")])],1),(
                      _vm.mostrarOpcaoEvidencia(
                        notificacao?.tipo,
                        notificacao?.status
                      )
                    )?_c('v-list-item',{on:{"click":function($event){return _vm.abrirDialogEvidencia(notificacao)}}},[_c('v-list-item-title',[_vm._v(" Evidência ")])],1):_vm._e()],1)],1):_vm._e()],1)],1),(index < _vm.notificacoes?.length - 1)?_c('v-divider'):_vm._e()],1)}),1),(_vm.carregandoProximaPagina && !_vm.erroCarregarNotificacoes)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e()],1),_c('DialogJson',{ref:"dialogJson"}),_c('DialogEvidencia',{ref:"dialogEvidencia",attrs:{"service":_vm.notificacaoService}}),_c('DialogFiltros',{ref:"dialogFiltros",attrs:{"contratos":_vm.contratos,"carregandoFiltros":_vm.carregandoNotificacoes},on:{"aplicarFiltros":_vm.carregarNotificacoes}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }