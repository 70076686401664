<template>
  <v-expansion-panel-header
    class="pa-1 d-flex justify-start"
    :style="!verificaEventoAtivo(regua) && 'cursor: unset'"
  >

    <div class="d-flex align-center">
      <v-switch
        v-model="reguaA.status"
        value="PRODUCAO"
        class="mr-2"
        v-if="checkCredentials(['admin', 'editor_regua'])"
        :disabled="reguaA.status == 'ARQUIVADA'"
        @click.stop.native.capture="
          reguaA.status === 'PRODUCAO' ? handlePararRegua(reguaA) : handleRodarRegua(reguaA)
        "
      ></v-switch>

      <p class="text-subtitle-1 regua__name mb-0 mr-2" :class="reguaA.status">
        {{ reguaA.nome }}
      </p>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" v-on="on" color="orange" small class="ml-2" dark>
            Teste A/B
          </v-chip>
        </template>

        <span>
          Amostra início:
          {{ reguaA.digito_verificador_inicio }}
        </span>
        <br />
        <span>
          Amostra fim:
          {{ reguaA.digito_verificador_fim }}
        </span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="ml-1" v-show="reguaA.observacao" v-on="on" small>mdi-text-long</v-icon>
        </template>
        <span>{{ reguaA.observacao }}</span>
      </v-tooltip>

      <!-- os perfis da regua A serão os mesmos da régua B, então aqui estou usando os perfis da régua A sem me preocupar com os perfis da régua B -->
      <v-chip
        small
        outlined
        link
        @click.stop="handleVisualizarPerfisRegua(reguaA)"
        v-if="reguaA.perfis?.length > 0 && reguaA.status != 'ARQUIVADA'"
        :color="reguaA.status != 'PRODUCAO' ? 'grey' : 'purple'"
        class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2"
      >
        <span class="text-subtitle-2 font-weight-black">
          {{ reguaA.perfis?.length }}
          {{ reguaA.perfis?.length > 1 ? "perfis vinculados" : "perfil vinculado" }}
        </span>
      </v-chip>

      <v-spacer></v-spacer>

      <div
        class="align-center regua__wrapper__menu ml-2"
        v-if="checkCredentials(['admin', 'editor_regua'])"
      >
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="handleEditarRegua(regua)">
              <v-list-item-title>Editar Informações</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleRedirectEvento(reguaA.id)">
              <v-list-item-title>
                Editar Eventos
                <v-icon color="#ef5350" class="ml-1"> mdi-alpha-a-circle-outline </v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleRedirectEvento(reguaB.id)">
              <v-list-item-title>
                Editar Eventos
                <v-icon color="#42A5F5" class="ml-1"> mdi-alpha-b-circle-outline </v-icon>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleVerResultados(reguaA.id)">
              <v-list-item-title>
                Ver Resultados
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="regua.id_regua_desafiante" @click="handleDefinirGanhadora(regua)">
              <v-list-item-title>Definir régua ganhadora</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleToggleRegua(regua)">
              <v-list-item-title>
                {{ regua.status != "ARQUIVADA" ? "Arquivar" : "Ativar" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-expansion-panel-header>
</template>
<script>
import { checkCredentials } from "@/assets/js/utils";
import { mapGetters } from "vuex";

export default {
  props: {
    regua: {
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      reguaA: null,
      reguaB: null,
    };
  },
  async created() {
    const reguaDesafiante = this.getReguaById(this.regua.id_regua_desafiante);

    if (this.regua.tipo_teste_ab === "A") {
      this.reguaA = this.regua;
      this.reguaB = reguaDesafiante;
    } else {
      this.reguaB = this.regua;
      this.reguaA = reguaDesafiante;
    }
  },
  computed: {
    ...mapGetters("reguas", {
      getReguaById: "getReguaById",
    }),
  },
  methods: {
    checkCredentials,
    verificaEventoAtivo(regua) {
      return regua.eventos.some((ev) => ev.status === "PRODUCAO");
    },
    handleVisualizarPerfisRegua(regua) {
      this.$emit("visualizarPerfisRegua", regua);
    },
    handleRedirectEvento(reguaId) {
      this.$emit("redirectEvento", reguaId);
    },
    handleEditarRegua(regua) {
      this.$emit("editarRegua", regua);
    },
    handleToggleRegua(regua) {
      this.$emit("toggleRegua", regua);
    },
    handleDefinirGanhadora() {
      this.$emit("definirGanhadora", { reguaA: this.reguaA, reguaB: this.reguaB });
    },
    handleRodarRegua(regua) {
      this.$emit("rodarRegua", regua);
    },
    handlePararRegua(regua) {
      this.$emit("pararRegua", regua);
    },
    handleVerResultados(regua_id) {
      this.$emit("verResultados", regua_id);
    }
  },
};
</script>
